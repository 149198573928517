<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                高新赋能｜非凸科技荣获国家级“高新技术企业”认定
              </p>
              <div class="fst-italic mb-2">2023年3月17日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >高新技术企业</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/29/01-高新企业.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                   2022年12月，非凸科技顺利通过上海市科技部、财政部、国家税务总局的严格评审，获得“国家级高新技术企业”认定，并最终荣获“高新技术企业证书”。此项荣誉是国家对非凸科技在技术创新、产品研发、服务能力等方面的高度认可，也是最佳激励。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/29/02-高新证书.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    成立以来，非凸科技始终关注前沿科技的研究并持续投入，在全球多地建立了研发部门。如今随着超算中心的建设完成，在算力等研发投入上每年超千万，实现了模型和策略的高效迭代，推动了算法生态的建设。非凸科技作为Rust先行者，敢为人先，在持续为金融机构提供优质的算法交易解决方案。
                  </p>
                  <p>
                    随着国内金融市场不断走向成熟，智能化、数字化正成为主流，算法交易的优势逐步放大，并成为机构投资者更为关注的部分。算法交易作为金融与科技充分结合的典型场景，在这一场景下，非凸科技将为客户提供更优质的科技工具，助力其提升机构服务竞争力，实现业务快速发展。
                  </p>
                  <p>
                    此前，非凸科技已通过软件产品认证、证监会信息技术系统备案，拥有30余项专利及软件著作权，以及作为智能投研技术联盟核心成员等，在创新能力、服务能力、知识产权等方面获得了多方权威认可，经受了市场与行业的双重检验。此次通过高新技术企业认定，也激励了非凸科技将努力开拓创新，积极回报国家及业界对非凸的信任和支持。
                  </p>
                  <p>
                    未来，非凸科技将持续积累行业经验与技术实力，探索业务发展前沿，持之以恒，砥砺创新，为行业发展不断赋能。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News29",
};
</script>

<style></style>
